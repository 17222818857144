import {
	FaBootstrap,
	FaCss3Alt,
	FaHtml5,
	FaReact,
	FaSass,
	FaRust,
	FaVuejs,
	FaPhp,
	FaAnchor,
	FaNodeJs,
	FaLaravel
} from "react-icons/fa";
import { DiJavascript1 } from "react-icons/di";
import {
	SiTailwindcss,
	SiNextdotjs,
	SiTypescript,
	SiSolana,
	SiMobx,
	SiMongodb,
	SiWeb3Dotjs,
	SiRedux,
	SiEthereum,
	SiVite,
} from "react-icons/si";

export const SkillsData = [
	{
		name: "HTML",
		icon: <FaHtml5 />,
	},
	{
		name: "CSS",
		icon: <FaCss3Alt />,
	},
	{
		name: "Javascript",
		icon: <DiJavascript1 />,
	},
	{
		name: "TypeScript",
		icon: <SiTypescript />,
	},
	{
		name: "React Js",
		icon: <FaReact />,
	},
	{
		name: "Vite",
		icon: <SiVite />
	},
	{
		name: "Next Js",
		icon: <SiNextdotjs />,
	},
	{
		name: "Redux",
		icon: <SiRedux />,
	},
	{
		name: "Mobx",
		icon: <SiMobx />,
	},
	{
		name: "Vue",
		icon: <FaVuejs />,
	},
	{
		name: "Bootstrap",
		icon: <FaBootstrap />,
	},
	{
		name: "Tailwind",
		icon: <SiTailwindcss />,
	},
	{
		name: "Sass",
		icon: <FaSass />,
	},
	{
		name: "PHP",
		icon: <FaPhp />,
	},
	{
		name: "Node.js",
		icon: <FaNodeJs />,
	},
	{
		name: "Mongodb",
		icon: <SiMongodb />,
	},
	{
		name: "Laravel",
		icon: <FaLaravel />,
	},
	{
		name: "Solana",
		icon: <SiSolana />,
	},
	{
		name: "Ethereum",
		icon: <SiEthereum />
	},
	{
		name: "Anchor",
		icon: <FaAnchor />,
	},
	{
		name: "Web3.js ",
		icon: <SiWeb3Dotjs  />,
	},
	{
		name: "Rust",
		icon: <FaRust />,
	},
];
