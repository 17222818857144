export const WorkData = [
	{
		title: "Raydium Volume Bot",
		desc: "This bot is designed to automate the distribution of SOL to multiple wallets and execute endless buy and sell swap transactions simultaneously on the Raydium and Meteora platform.",
		tech: ["Typescript", "Solana", "Raydium Swap"],
		gitlink: "//github.com/vladmeer/raydium-volume-bot",
		site: "//github.com/vladmeer/raydium-volume-bot"
	},
	{
		title: "Pumpfun Bundler",
		desc: "This is the best, fastest, and most efficient self-bundling script for PumpFun, allowing you to create a token and buy tokens with your own 20 different wallets in one single bundle.",
		tech: ["Typescript", "Solana","Pump.fun SDK", "Jito"],
		gitlink: "//github.com/vladmeer/pumpfun-bundler",
		site: "//github.com/vladmeer/pumpfun-bundler"
	},
	{
		title: "Raydium sniper",
		desc: "Want to snipe newly launched tokens on Raydium within the first block or even the first second after pool creation? With my custom-built sniper tool, you can achieve just that!",
		tech: ["Typescript", "Solana", "Jupiter", "Jito", "WebSocket", "Helius"],
		gitlink: "//github.com/vladmeer/Raydium-sniper",
		site: "//github.com/vladmeer/Raydium-sniper"
	},
	{
		title: "Raydium bundler",
		desc: "Solana Raydium Bundler: Raydium Bundler with 21 wallets",
		tech: ["Typescript", "Solana", "Jito"],
		gitlink: "//github.com/vladmeer/raydium-bundler",
		site: "//github.com/vladmeer/raydium-bundler"
	},
	{
		title: "Copy Trading Bot",
		desc: "A copy trading bot on Solana that tracks a trader's wallet and automatically buys and sells the same assets whenever the trader does.",
		tech: ["Typescript", "Solana","WebSocket", "Helius"],
		gitlink: "//github.com/vladmeer/copy-trading-bot",
		site: "//github.com/vladmeer/copy-trading-bot"
	},
	{
		title: "HydraSwap",
		desc: "Extended backend features and rust contract side",
		tech: ["Rust", "Anchor", "Hydra Swap"],
		gitlink: "//github.com/vladmeer/the-movie-hub",
		site: "//hydraswap.io/",
	},
	{
		title: "Degen Taxi",
		desc: "One kind of Sol crash game",
		tech: ["Web3", "Anchor", "Typescript","Next.js"],
		gitlink: "//github.com/vladmeer/Digitaly",
		site: "//degentaxi.io",
	},
	{
		title: "BASC NFT Staking V2",
		desc: "This is an individual and matching pair staking on Solana and Users can stake BASC NFTs and earn $DAB token as reward.I wrote the staking contract and web3 integration.",
		tech: ["Next.js", "NFT staking", "Anchor","Web3"],
		gitlink: "//github.com/vladmeer/Digitaly",
		site: "//stakingv2.babyapesocialclub.com",
	},
	{
		title: "Poly186",
		desc: "Built the website from scratch and worked as a member from the initial stage of the project.Made P8 token and liquidity pool based on Gnosis chain. Was building a Polyverse platform.",
		tech: ["Polyverse platform", "Gnosis chain", "P8 token"],
		gitlink: "//github.com/vladmeer/netflix-clone",
		site: "//www.poly186.io",
	},
	{
		title: "LeanScale",
		desc: "Built the Ecommerce platform using Next.js and API integration.Led the front-end team as lead developer, Managed source code using Git and CI/CD - pipeline.",
		tech: ["Next.js"],
		gitlink: "//www.github.com/vladmeer/instablog",
		site: "//www.leanscale.com/",
	},
	{
		title: "Carefizz ",
		desc: "Health & Fitness site and they bring the most popular entertainment content directly to user mobile devices.",
		tech: ["Vue", "Typescript","Node.js", "Mongoose"],
		gitlink: "//github.com/vladmeer/Digitaly",
		site: "//lv.carefizz.com",
	},
	{
		title: "Hike Wild Montana",
		desc: "People and wildlife flourish because public lands and waters are wild and connected.",
		tech: ["React.js", "Redux", "Mapbox GL"],
		gitlink: "//github.com/vladmeer/Digitaly",
		site: "//wildmontana.org/hike",
	},
	{
		title: "Events Smarter",
		desc: "Events Smarter welcomes users into a user-friendly experience ",
		tech: ["PHP", "Vue", "Laravel","Mysql"],
		gitlink: "//github.com/vladmeer/Digitaly",
		site: "//www.eventssmarter.com",
	},
	{
		title: "Mad Paws Dinner Bowl ",
		desc: "Australia's one-stop shop for healthy, quality dog food delivered to customer’s doors.",
		tech: ["Next.js", "TailwindCss","Node.js", "Mongoose"],
		gitlink: "//github.com/vladmeer/Digitaly",
		site: "//madpawsdinnerbowl.com.au",
	},
	{
		title: "YAKU Hub",
		desc: "Yaku Hub is your access pass to a smarter platform that delivers value to the ecosystem as a whole.Worked on the front-end part using Next.js and integrated Web3.",
		tech: ["Next.js", "TailwindCss", "Typescript","Web3"],
		gitlink: "//github.com/vladmeer/Digitaly",
		site: "//www.yaku.ai",
	},
];
