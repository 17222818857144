import React from 'react';
import { Link } from 'react-router-dom';
import { FaTwitter} from "react-icons/fa"
import {BiLogoTelegram} from "react-icons/bi"
import {BsGithub} from "react-icons/bs"

const NavLinks = ({handleNav}) => {
  return (
    <ul className='nav-links'>
        <li onClick={handleNav} ><Link to="//t.me/vladmeer67" target='_blank' className='nav-link'><BiLogoTelegram /></Link></li>
        <li onClick={handleNav} ><Link to="//github.com/vladmeer" target='_blank' className='nav-link'><BsGithub /></Link></li>
        <li onClick={handleNav} ><Link to="//x.com/vladmeer67" target='_blank' className='nav-link'><FaTwitter /></Link></li>
  </ul>
  )
};

export default NavLinks;
