import React from "react";
import About from "./About";
import Contact from "./Contact";
import HeroSection from "./HeroSection";
import Services from "./Services";
import Skills from "./Skills";
import Works from "./Works";
import Navbar from "../components/navbar/Navbar";

const   Main = ({ nav, handleNav, closeNav }) => {
  return (
    <div onClick={closeNav} className="main">
      <div style={{ marginLeft: "300px" }}>
        <HeroSection nav={nav} handleNav={handleNav} />
        <Navbar nav={true} handleNav={handleNav} />
        <About />
        <Skills />
        <Services />
        <Works />
        <Contact />
      </div>
    </div>
  );
};

export default Main;
